


























































import { Vue, Component } from 'vue-property-decorator';
import { deepCopy, exportBlobFile } from '@/libs/util';
import { gernerateCsv, gernerateJSON, gernerateSql } from './mock';
import MockDefine from './mock-define.vue';
@Component({
  components: { MockDefine }
})
export default class Home extends Vue {
  dataSource: any[] = [
    {
      conf: {
        dataType: 'Number',
        type: 'increment'
      },
      field: 'id'
    },
    {
      conf: {
        dataType: 'String',
        type: 'cname'
      },
      field: 'name'
    },
    {
      conf: {
        dataType: 'String',
        type: 'email'
      },
      field: 'email'
    },
    {
      conf: {
        dataType: 'Number',
        type: 'integer',
        min: 1,
        max: 100
      },
      field: 'age'
    },
    {
      conf: {
        dataType: 'Regexp',
        regexp: '\\w{3,8}@kpmg\\.com'
      },
      field: 'e'
    }
  ];

  previewCsv = '';
  previewJson = '';
  previewSql = '';

  addField() {
    this.dataSource.push({ conf: {} });
  }

  copyField(index: number) {
    let data = deepCopy(this.dataSource[index]);
    this.dataSource.push(data);
  }

  removeField(index: number) {
    this.dataSource.splice(index, 1);
  }

  getPreview() {
    let csv = gernerateCsv(10, this.dataSource);
    this.previewCsv = csv;
    let json = gernerateJSON(10, this.dataSource);
    this.previewJson = json;
    let sql = gernerateSql(10, this.dataSource, this.tableName);
    this.previewSql = sql;
  }

  dataCount = 10;
  tableName: any = 'tablename';
  exportJSON() {
    let data = gernerateJSON(this.dataCount, this.dataSource);
    exportBlobFile('mockJsonData.json', data);
  }

  exportCSV() {
    let data = gernerateCsv(this.dataCount, this.dataSource);
    exportBlobFile('mockCsvData.csv', data);
  }

  exportSQL() {
    let data = gernerateSql(this.dataCount, this.dataSource, this.tableName);
    exportBlobFile('mockSqlData.sql', data);
  }
}
