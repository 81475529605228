import { mock, Random } from 'mockjs';

const getStringItem = (item: any) => {
  let { field, conf } = item;
  let key = conf.type === 1 ? `${field}|1` : field;
  let valueObj: any = {
    0: conf.value,
    1: conf.options?.split(','),
    email: Random.email(),
    cname: Random.cname(),
    cparagraph: Random.cparagraph(),
    csentence: Random.csentence(),
    'cword(2,4)': Random.cword(2, 4),
    province: Random.province(),
    city: Random.city(),
    'city(true)': Random.city(true),
    'county(true)': Random.county(true),
    ip: Random.ip(),
    name: Random.name(),
    paragraph: Random.paragraph(),
    sentence: Random.sentence(),
    word: Random.word()
  };
  let value: any = valueObj[conf.type];
  return { key, value };
};

const getNumberItem = (item: any, increment: number) => {
  let { field, conf } = item;
  let key = conf.type === 1 ? `${field}|1` : field;
  let valueObj: any = {
    0: conf.value,
    1: conf.options?.split(','),
    integer: Random.integer(conf.min, conf.max),
    float: Random.float(conf.min, conf.max),
    increment: increment
  };
  let value: any = valueObj[conf.type];
  return { key, value };
};

const getDateTimeItem = (item: any) => {
  let { field, conf } = item;
  let key = field;
  let valueObj: any = {
    date: Random.date(conf.format),
    time: Random.time(conf.format),
    datetime: Random.datetime(conf.format),
    now: Random.now(conf.format)
  };
  let value: any = valueObj[conf.type];
  return { key, value };
};

export const getMockObj = (config: any[], increment: number) => {
  let obj: any = {};
  config.forEach(v => {
    if (v.conf.dataType === 'String') {
      let { key, value } = getStringItem(v);
      obj[key] = value;
    }
    if (v.conf.dataType === 'Number') {
      let { key, value } = getNumberItem(v, increment);
      obj[key] = value;
    }
    if (v.conf.dataType === 'DateTime') {
      let { key, value } = getDateTimeItem(v);
      obj[key] = value;
    }
    if (v.conf.dataType === 'Boolean') {
      obj[v.field] = Random.boolean();
    }
    if (v.conf.dataType === 'Regexp') {
      obj[v.field] = new RegExp(v.conf.regexp);
    }
  });
  return obj;
};

const gernerateData = (n: number, config: any[]) => {
  let data: any[] = [];
  for (let i = 0; i < n; i++) {
    data.push(mock(getMockObj(config, i + 1)));
  }
  return data;
};

export const gernerateCsv = (n: number, config: any[]) => {
  let data = gernerateData(n, config);
  let header = config.map(v => v.field);
  let csv = header.join(',') + `\n`;
  data.forEach(v => {
    let list: any[] = [];
    header.forEach(h => {
      list.push(v[h]);
    });
    csv += list.join(',') + `\n`;
  });
  return csv;
};

export const gernerateSql = (n: number, config: any[], tablename = 'tablename') => {
  let data = gernerateData(n, config);
  let header = config.map(v => v.field);
  let f = header.join(',');
  let cnt = '';
  data.forEach(v => {
    cnt += `INSERT INTO ${tablename}(${f})\nVALUES(`;
    header.forEach((h, index) => {
      cnt += index === header.length - 1 ? `'${v[h]}'` : `'${v[h]}',`;
    });
    cnt += `);\n`;
  });
  return cnt;
};

export const gernerateJSON = (n: number, config: any[]) => {
  let data = gernerateData(n, config);
  return JSON.stringify(data, null, 2);
};
